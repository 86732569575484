import { useEffect, useState, useMemo } from 'react';
import { PageHeader } from '@/shared/components/PageHeader';
import {
  Order as OrderType,
  Customer as CustomerType,
  OrderItem as OrderItemType,
  Employee as EmployeeType,
} from '@/types';
import {
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import IndeterminateCheckbox from '@/shared/components/Checkbox';
import DebouncedInput from '@/shared/components/DebouncedInput';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import cn from '@/shared/helpers/classNames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '@/shared/helpers/formatDate';
import { dateRangeFilter, fuzzyFilter } from '@/shared/helpers/tablefilters';
import { exportSelectedRows } from '@/shared/helpers/exportSelectedRows';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '@/shared/components/Pagination';
import { usePaginationStore } from '@/store';
import exportOrders from './exportOrders';
import { useAuth } from '@clerk/clerk-react';
import Switch from '@/shared/components/Switch';
import Loading from '@/shared/components/Loading';

type ListResponseItem = {
  order: OrderType;
  customer: CustomerType;
  employee: EmployeeType;
  itemCount: number;
};

const Orders: React.FC = () => {
  const apiUrl = window.env?.API_URL || '';

  const navigate = useNavigate();
  const [data, setData] = useState<ListResponseItem[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfItemsPerPage = usePaginationStore(state => state.numberOfItemsPerPage);

  const { getToken } = useAuth();
  const fetchData = async () => {
    setLoading(true);
    const token = await getToken();
    fetch(`${apiUrl}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then((data: ListResponseItem[]) => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data from API', error);
        setLoading(false);
      });
  };

  async function markOrderAsFulilled(orderId: number, exported: boolean) {
    setLoading(true);
    const token = await getToken();
    const url = `${apiUrl}/order-fulfilled`;
    const data = {
      orderId: orderId.toString(),
      exported: exported.toString(),
    };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        console.log(jsonResponse.message);
        // refresh data
        fetchData();
        setLoading(false);
      } else {
        console.error('Error:', jsonResponse.error);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error marking order as fulfilled:', error);
    }
  }

  // Fetch order items CSV from server
  async function markFulfilled() {
    setLoading(true);
    const token = await getToken();
    const selectedRows = tableInstance.getFilteredSelectedRowModel().rows;
    // console.log("Selected rows:", selectedRows)
    const ids = selectedRows.map((row: any) => row?.original?.order?.id);
    const apiUrl = window.env?.API_URL || '';
    const response = await fetch(`${apiUrl}/orders-fulfilled?orderIds=${ids.join(',')}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token || ''}`,
      },
    });
    if (response.ok) {
      setLoading(false);
      // refresh data
      fetchData();
    } else {
      console.error('Error fetching CSV:', response.statusText);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }: { table: any }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }: { row: any }) => (
          <div className="flex items-center">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: 'id',
        header: 'Order Num',
        accessor: 'id',
        accessorKey: '.',
        // clickable button that redirects to order details
        cell: ({ row }: { row: any }) => (
          <button
            onClick={() => navigate(`/orders/${row.original.order.id}`)}
            className="font-medium text-gray-900 whitespace-nowrap"
          >
            {row?.original?.order.id || ''}
          </button>
        ),
        sortingFn: (rowA: any, rowB: any, columnId: any) => {
          return rowA.original.order.id - rowB.original.order.id;
        },
      },
      {
        id: 'name',
        header: 'Customer name',
        accessor: 'customer.name',
        accessorKey: '.',
        // clickable button that redirects to order details
        cell: ({ row }: { row: any }) => (
          <button
            onClick={() => navigate(`/orders/${row.original.order.id}`)}
            className="font-medium text-gray-900 whitespace-nowrap"
          >
            {row?.original?.customer?.name || ''}
          </button>
        ),
        sortingFn: (rowA: any, rowB: any, columnId: any) => {
          const nameA = rowA.original.customer.name;
          const nameB = rowB.original.customer.name;
          if (nameA === nameB) return 0;
          return nameA > nameB ? 1 : -1;
        },
      },
      {
        id: 'sales_rep',
        header: 'Sales Rep',
        accessor: 'employee.name',
        accessorKey: '.',
        // cell: ({ row }: { row: any }) => <span>{row.original.employee.name}</span>,
        cell: ({ row }: { row: any }) => (
          <button onClick={() => navigate(`/orders/${row.original.order.id}`)} className="">
            {row?.original?.employee.name || ''}
          </button>
        ),
        sortingFn: (rowA: any, rowB: any, columnId: any) => {
          const nameA = rowA.original.employee.name;
          const nameB = rowB.original.employee.name;
          if (nameA === nameB) return 0;
          return nameA > nameB ? 1 : -1;
        },
      },
      // {
      //   id: "exported",
      //   header: "Fulfilled",
      //   accessorKey: ".",
      //   accessor: "order.exported_at",
      //   // add toggle button to mark order as fulfilled
      //   cell: ({ row }: { row: any }) => (
      //     <Switch
      //       checked={!!row?.original?.order?.exported_at}
      //       onChange={(checked) => markOrderAsFulilled(row?.original?.order?.id, checked)}
      //     />
      //   ),
      //   sortingFn: (rowA: any, rowB: any, columnId: any) => {
      //     const exportedA = rowA.original.order.exported_at;
      //     const exportedB = rowB.original.order.exported_at;
      //     if (exportedA === exportedB) return 0;
      //     if (exportedA) return 1;
      //     return -1;
      //   }
      // },
      {
        // items count
        id: 'items',
        header: 'Items',
        accessorKey: '.',
        accessor: 'itemCount',
        // cell: ({ row }: { row: any }) => <span>{row.original.itemCount}</span>,
        cell: ({ row }: { row: any }) => (
          <button onClick={() => navigate(`/orders/${row.original.order.id}`)} className="">
            {row.original.itemCount}
          </button>
        ),
        sortingFn: (rowA: any, rowB: any, columnId: any) => {
          return rowA.original.itemCount - rowB.original.itemCount;
        },
      },
      {
        id: 'created_at',
        header: 'Date Ordered',
        filterFn: 'dateRange', // Apply the date range filter
        accessorFn: (row: ListResponseItem) => {
          const d = new Date(row?.order?.created_at);
          d.setHours(0, 0, 0, 0);
          return formatDate({
            date: d,
            format: 'MMMM Do YYYY',
          });
        },
      },
      {
        id: 'delivery_date',
        header: 'Delivery Date',
        // filterFn: 'dateRange', // Apply the date range filter
        accessorFn: (row: ListResponseItem) => {
          if (row?.order?.delivery_date) {
            return row?.order?.delivery_date;
            // const d = new Date(row?.order?.delivery_date);
            // d.setHours(0, 0, 0, 0);
            // return formatDate({
            //   date: d,
            //   format: "MMMM Do YYYY",
            // });
            // }
          }
          return '';
        },
      },
    ],
    []
  );

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    const newFilters = columnFilters.filter(filter => filter.id !== 'created_at');
    if (startDate || endDate) {
      newFilters.push({ id: 'created_at', value: [startDate, endDate] });
    }
    setColumnFilters(newFilters);
  }, [startDate, endDate]);

  const tableInstance = useReactTable({
    // @ts-ignore
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
      dateRange: dateRangeFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      pagination: {
        pageSize: numberOfItemsPerPage,
      },
    },
  });

  // when number of items per page changes, update the table
  useEffect(() => {
    tableInstance.setPageSize(numberOfItemsPerPage);
  }, [numberOfItemsPerPage]);

  const handleExport = async () => {
    setLoading(true);
    const token = await getToken();
    const selectedRows = tableInstance.getFilteredSelectedRowModel().rows;
    const ids = selectedRows.map((row: any) => row?.original?.order?.id);
    await exportOrders(ids, token);
    setLoading(false);
    // refresh data
    fetchData();
  };

  return (
    <div className="flex flex-col w-full px-4">
      <Loading loading={loading} />
      <div className="relative">
        <PageHeader
          title="Orders"
          subHeading=""
          leftElement={
            <>
              <button
                onClick={handleExport}
                title="Exports as CSV. Select one or more orders to export"
                disabled={tableInstance.getFilteredSelectedRowModel().rows.length === 0}
                className="text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 sm:mr-2 disabled:opacity-50"
              >
                Export
              </button>
              {/* <button
            onClick={markFulfilled}
            disabled={tableInstance.getFilteredSelectedRowModel().rows.length === 0}
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mb-2 sm:mb-0 sm:mr-2 disabled:opacity-50"
          >
            Mark as fulfilled
          </button> */}
            </>
          }
        />
      </div>
      <div className="bg-white text-slate-900 flex flex-col w-full rounded-lg">
        <div className="flex flex-col sm:flex-row px-6 mt-6">
          <div className="flex-1 mb-2 sm:mb-0 sm:mr-2">
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={value => setGlobalFilter(String(value))}
              className="p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full"
              placeholder="Search..."
            />
          </div>
          <div className="flex flex-col sm:flex-row items-start sm:items-center">
            <DatePicker
              key="start-date"
              selected={startDate}
              onChange={date => {
                date?.setHours(0, 0, 0, 0);
                setStartDate(date);
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              isClearable={!!startDate}
              placeholderText="Start Date"
              className="p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 mb-2 sm:mb-0 sm:mr-2"
            />
            <DatePicker
              key="end-date"
              selected={endDate}
              onChange={date => {
                date?.setHours(23, 59, 59, 999);
                setEndDate(date);
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              isClearable={!!endDate}
              placeholderText="End Date"
              className="p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 rounded-md"
            />
          </div>
        </div>
        <div className="w-full mx-auto">
          <div className="space-x-4 p-6 w-full overflow-x-auto">
            <table className="min-w-full bg-white w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                {tableInstance.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan} className="px-6 py-3">
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: cn(
                                  'flex items-center tracking-wider cursor-pointer select-none',
                                  header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                                ),
                                id: header.id,
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: <FaSortUp />,
                                desc: <FaSortDown />,
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>{/* <Filter column={header.column} /> */}</div>
                            ) : null}
                          </>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {tableInstance.getRowModel().rows.map(row => (
                  <tr
                    key={row.id}
                    className={`bg-white border-b hover:bg-gray-50  ${
                      row?.original?.order?.exported_at ? `grayed-out` : ``
                    }`}
                  >
                    {row.getVisibleCells().map(cell => (
                      <td className="px-6 py-3" key={cell.column.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="p-6">
            <Pagination
              totalItems={tableInstance.getRowCount()}
              currentPage={currentPage}
              onPageChange={(page: { selected: number }) => {
                setCurrentPage(page.selected);
                tableInstance.setPageIndex(page.selected);
              }}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
